import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";

function CommunityGuidelines() {
  return (
    <div className="App">
      <Helmet>
        <title>Nexxy - Community Guidelines</title>
        <meta
          name="description"
          content="Nexxy Community Guidelines - Learn the rules and guidelines to keep our community safe and inclusive."
        />
        <meta
          name="keywords"
          content="Nexxy, community guidelines, safe environment, social networking"
        />
        <meta name="author" content="Nexxy LLC" />
        <link
          rel="canonical"
          href="https://www.nexxyapp.com/community-guidelines"
        />

        <meta property="og:title" content="Nexxy - Community Guidelines" />
        <meta
          property="og:description"
          content="Learn the rules and guidelines to keep our community safe and inclusive on Nexxy."
        />
        <meta property="og:image" content="path-to-social-media-image.png" />
        <meta
          property="og:url"
          content="https://www.nexxyapp.com/community-guidelines"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nexxy - Community Guidelines" />
        <meta
          name="twitter:description"
          content="Nexxy Community Guidelines. Learn the rules and guidelines to keep our community safe and inclusive."
        />
        <meta name="twitter:image" content="path-to-social-media-image.png" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://www.nexxyapp.com/community-guidelines",
              "name": "Nexxy - Community Guidelines"
            }
          `}
        </script>
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
        </div>
        <nav className="nav-links">
          <a href="/">Download</a>
          <a href="/">Wait List</a>
        </nav>
      </header>

      <section className="CommunityGuidelines">
        <h2>Community Guidelines</h2>
        <p>
          <strong>Effective Date:</strong> 8/27/24
        </p>

        <h3>1. Respectful Communication</h3>
        <p>
          <strong>No Hate Speech or Harassment:</strong> We do not tolerate any
          form of hate speech, bullying, or harassment. This includes content or
          behavior that attacks, threatens, or demeans individuals or groups
          based on race, ethnicity, national origin, religion, gender, gender
          identity, sexual orientation, disability, or any other characteristic.
        </p>
        <p>
          <strong>No Threats of Violence:</strong> Any form of threat, whether
          explicit or implied, towards other users or individuals is strictly
          prohibited. This includes threats of physical harm, destruction of
          property, or any action that could lead to fear or anxiety.
        </p>
        <p>
          <strong>Constructive Interaction:</strong> Engage in conversations
          that are constructive and civil. Disagreements are natural, but they
          should be handled with respect and openness to different perspectives.
        </p>

        <h3>2. Content Guidelines</h3>
        <p>
          <strong>No Inappropriate Content:</strong> Nexxy is not a place for
          pornography, sexually explicit material, or any other content that is
          inappropriate for a diverse audience. This includes images, videos, or
          links to external sites containing such content.
        </p>
        <p>
          <strong>No Graphic Violence:</strong> Content that glorifies or
          depicts graphic violence, gore, or abuse is not allowed. Content
          intended for educational purposes related to violence should be shared
          responsibly and with appropriate warnings.
        </p>
        <p>
          <strong>No Promotion of Illegal Activities:</strong> Content that
          promotes or glorifies illegal activities, including drug use, illegal
          weapons, human trafficking, or other criminal behavior, is strictly
          forbidden.
        </p>

        <h3>3. Authenticity</h3>
        <p>
          <strong>No Impersonation:</strong> Users must not impersonate other
          individuals, celebrities, or brands. Misrepresentation of identity,
          including the use of fake profiles, is not allowed.
        </p>
        <p>
          <strong>No Misinformation:</strong> We are committed to preventing the
          spread of false information. Users should not post or share false or
          misleading content that could cause harm or confusion. This includes,
          but is not limited to, fake news, conspiracy theories, and health
          misinformation.
        </p>

        <h3>4. Privacy and Security</h3>
        <p>
          <strong>Respect Privacy:</strong> Respect the privacy of others by not
          sharing personal, confidential, or sensitive information without
          explicit permission. This includes contact information, addresses,
          financial information, and any other private data.
        </p>
        <p>
          <strong>No Doxxing:</strong> Doxxing, or the act of publishing private
          information about an individual without their consent, with the intent
          to harass, intimidate, or harm, is strictly prohibited.
        </p>

        <h3>5. Intellectual Property</h3>
        <p>
          <strong>Respect Copyrights:</strong> Users must respect the
          intellectual property rights of others. Do not post content that
          infringes on someone else's copyright, trademark, or other proprietary
          rights. This includes music, videos, images, and written work.
        </p>
        <p>
          <strong>No Plagiarism:</strong> All content shared on Nexxy should be
          original or properly credited. Plagiarism, or the act of presenting
          someone else's work as your own, is not allowed.
        </p>

        <h3>6. Spam and Scams</h3>
        <p>
          <strong>No Spamming:</strong> Spammy behavior, such as repeated
          posting of the same content, unsolicited messages, or irrelevant
          promotional content, disrupts the user experience and is not
          permitted.
        </p>
        <p>
          <strong>No Scams:</strong> Nexxy has zero tolerance for fraudulent
          activities, including phishing schemes, financial scams, and other
          deceptive practices intended to exploit or deceive users.
        </p>

        <h3>7. Safe Environment</h3>
        <p>
          <strong>No Encouragement of Harmful Behavior:</strong> Nexxy is a safe
          space, and we do not allow content that encourages or glorifies
          self-harm, eating disorders, suicide, or other dangerous behaviors.
          Content intended to raise awareness about these issues should be
          shared responsibly and with appropriate context.
        </p>
        <p>
          <strong>Reporting Violations:</strong> If you encounter any content or
          behavior that violates these guidelines, please report it immediately
          using the reporting tools available on Nexxy. We take reports
          seriously and will take appropriate action to address violations.
        </p>

        <h3>8. Consequences for Violations</h3>
        <p>
          <strong>Enforcement:</strong> Violations of these Community Guidelines
          may result in content removal, account suspension, or permanent
          banning from Nexxy. We reserve the right to take any action we deem
          necessary to protect the safety and well-being of our community.
        </p>
        <p>
          <strong>Appeals:</strong> If you believe that a decision to remove
          content or suspend your account was made in error, you may submit an
          appeal through the designated process. We will review appeals on a
          case-by-case basis and respond accordingly.
        </p>

        <h3>9. Updates to the Guidelines</h3>
        <p>
          <strong>Changes:</strong> Nexxy may update these Community Guidelines
          from time to time to reflect changes in our practices or for other
          operational, legal, or regulatory reasons. We encourage users to
          review these guidelines periodically to stay informed about our
          expectations.
        </p>
      </section>

      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <a href="/tos">Terms of Service</a>
          <a href="/privacy">Privacy Policy</a>
        </nav>
      </footer>
    </div>
  );
}

export default CommunityGuidelines;
