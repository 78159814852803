import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import { Link } from "react-router-dom";

function Tos() {
  return (
    <div className="App">
      <Helmet>
        <title>Nexxy - Terms of Service</title>
        <meta
          name="description"
          content="Nexxy Terms of Service - Learn the rules and guidelines for using our social media platform."
        />
        <meta
          name="keywords"
          content="Nexxy, terms of service, rules, guidelines, social networking"
        />
        <meta name="author" content="Nexxy LLC" />
        <link rel="canonical" href="https://www.nexxyapp.com/terms" />

        <meta property="og:title" content="Nexxy - Terms of Service" />
        <meta
          property="og:description"
          content="Learn the rules and guidelines for using Nexxy, a social media platform."
        />
        <meta property="og:image" content="path-to-social-media-image.png" />
        <meta property="og:url" content="https://www.nexxyapp.com/terms" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nexxy - Terms of Service" />
        <meta
          name="twitter:description"
          content="Nexxy Terms of Service. Learn the rules and guidelines for using our platform."
        />
        <meta name="twitter:image" content="path-to-social-media-image.png" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://www.nexxyapp.com/terms",
              "name": "Nexxy - Terms of Service"
            }
          `}
        </script>
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <Link to="/">
            <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
          </Link>
        </div>
        <nav className="nav-links">
          <Link to="/">Download</Link>
          <Link to="/">Wait List</Link>
        </nav>
      </header>

      <section className="TermsOfService">
        <h2>Terms of Service</h2>
        <p>
          <strong>Effective Date:</strong> 8/27/24
        </p>

        <h3>1. Introduction</h3>
        <p>
          Welcome to Nexxy, a social media application provided by Nexxy LLC. By
          accessing or using our services on iOS, Android, or the web, you agree
          to be bound by these Terms of Service (the "Terms"). Please read these
          Terms carefully. By using Nexxy, you also agree to adhere to our{" "}
          <Link to="/community-guidelines">Community Guidelines</Link>.
        </p>

        <h3>2. Eligibility</h3>
        <p>
          To use Nexxy, you must be at least 16 years old. By using the app, you
          confirm that you meet this age requirement.
        </p>

        <h3>3. Account Registration</h3>
        <p>
          You may need to create an account to access certain features of Nexxy.
          When creating an account, you agree to provide accurate and complete
          information, including your name, email address, and birthday. You are
          responsible for maintaining the confidentiality of your account and
          password.
        </p>

        <h3>4. User Conduct</h3>
        <p>You agree not to:</p>
        <ul>
          <li>
            Upload, post, or share any content that is pornographic, sexually
            explicit, or otherwise violates our content policies.
          </li>
          <li>
            Engage in any unlawful, harmful, or abusive behavior while using
            Nexxy.
          </li>
          <li>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity.
          </li>
        </ul>

        <h3>5. Content Ownership and License</h3>
        <p>
          You retain ownership of any content you post on Nexxy. However, by
          posting content, you grant Nexxy a worldwide, non-exclusive,
          royalty-free license to use, distribute, and display your content in
          connection with providing our services.
        </p>

        <h3>6. Account and Content Suspension or Termination</h3>
        <p>
          Nexxy reserves the right to suspend or terminate your account, or
          remove or suspend any of your posts, at any time, for any reason,
          including if we deem your content or behavior to be harmful, or no
          reason at all, without prior notice.
        </p>

        <h3>7. Advertising</h3>
        <p>
          Nexxy uses AdMob to display advertisements. By using the app, you
          consent to the display of ads that may be tailored based on your
          interactions with the app.
        </p>

        <h3>8. Data Storage and Security</h3>
        <p>
          We store user data, including your name, email address, IP address,
          and hashed password, in MongoDB. Images are stored in Google Buckets,
          and our backend is hosted on Google Cloud. While we take appropriate
          security measures to protect your data, you acknowledge that no system
          is completely secure.
        </p>

        <h3>9. Push Notifications</h3>
        <p>
          Nexxy uses NativeNotify to send push notifications. By creating an
          account, you consent to receiving push notifications, which you can
          manage in your account settings.
        </p>

        <h3>10. Compliance with Laws</h3>
        <p>
          You agree to comply with all applicable laws, including but not
          limited to those related to data protection and privacy.
        </p>

        <h3>11. Verification Process</h3>
        <p>
          Applying for verification on Nexxy is free. We reserve the right to
          approve or deny verification requests at our discretion. If your
          verification request is denied, we will provide a reason for the
          denial. All verification requests can take a few months to be
          processed.
        </p>

        <h3>12. Governing Law</h3>
        <p>
          These Terms are governed by the laws of the State of California,
          without regard to its conflict of law principles.
        </p>

        <h3>13. Changes to the Terms</h3>
        <p>
          We may modify these Terms at any time. Your continued use of Nexxy
          after any changes indicates your acceptance of the new Terms.
        </p>
      </section>

      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/community-guidelines">Community Guidelines</Link>
        </nav>
      </footer>
    </div>
  );
}

export default Tos;
