import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";

function Privacy() {
  return (
    <div className="App">
      <Helmet>
        <title>Nexxy - Privacy Policy</title>
        <meta
          name="description"
          content="Nexxy Privacy Policy - Learn how we protect your privacy and handle your personal data."
        />
        <meta
          name="keywords"
          content="Nexxy, privacy policy, data protection, personal information, social networking"
        />
        <meta name="author" content="Nexxy LLC" />
        <link rel="canonical" href="https://www.nexxyapp.com/privacy" />

        <meta property="og:title" content="Nexxy - Privacy Policy" />
        <meta
          property="og:description"
          content="Learn how Nexxy protects your privacy and handles your personal data."
        />
        <meta property="og:image" content="path-to-social-media-image.png" />
        <meta property="og:url" content="https://www.nexxyapp.com/privacy" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nexxy - Privacy Policy" />
        <meta
          name="twitter:description"
          content="Nexxy Privacy Policy. Learn how we protect your data."
        />
        <meta name="twitter:image" content="path-to-social-media-image.png" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://www.nexxyapp.com/privacy",
              "name": "Nexxy - Privacy Policy"
            }
          `}
        </script>
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
        </div>
        <nav className="nav-links">
          <a href="/">Download</a>
          <a href="/">Wait List</a>
        </nav>
      </header>

      <section className="PrivacyPolicy">
        <h2>Privacy Policy</h2>
        <p>
          <strong>Effective Date:</strong> 8/27/24
        </p>

        <h3>1. Introduction</h3>
        <p>
          Nexxy LLC ("we", "us", "our") is committed to protecting your privacy.
          This Privacy Policy explains how we collect, use, and share
          information about you when you use our social media app, Nexxy,
          available on iOS, Android, and the web.
        </p>

        <h3>2. Information We Collect</h3>
        <p>
          <strong>Personal Information:</strong> We collect your name, email
          address, birthday, and IP address when you create an account. Your
          password is securely hashed and stored in our database.
        </p>
        <p>
          <strong>Usage Information:</strong> We collect information about your
          interactions with the app, such as the pages you visit, the content
          you view, and the features you use.
        </p>
        <p>
          <strong>Device Information:</strong> We may collect information about
          the device you use to access Nexxy, including your device's IP
          address, operating system, and browser type.
        </p>

        <h3>3. How We Use Your Information</h3>
        <p>
          <strong>To Provide Our Services:</strong> We use your information to
          operate and improve Nexxy, including personalizing your experience and
          displaying relevant ads.
        </p>
        <p>
          <strong>To Communicate with You:</strong> We may use your email
          address to send you updates, newsletters, and other communications
          related to your use of Nexxy.
        </p>
        <p>
          <strong>For Security Purposes:</strong> We use your information to
          protect the security and integrity of our app and to prevent
          fraudulent activity.
        </p>

        <h3>4. Data Storage and Security</h3>
        <p>
          Your data is stored in MongoDB, with images stored in Google Buckets
          and our backend hosted on Google Cloud. We implement industry-standard
          security measures to protect your data but cannot guarantee its
          absolute security.
        </p>

        <h3>5. Sharing Your Information</h3>
        <p>
          <strong>Service Providers:</strong> We may share your information with
          third-party service providers who assist us in operating Nexxy, such
          as AdMob for advertisements and NativeNotify for push notifications.
        </p>
        <p>
          <strong>Legal Compliance:</strong> We may disclose your information if
          required to do so by law or in response to valid legal requests from
          public authorities.
        </p>

        <h3>6. Your Rights</h3>
        <p>
          <strong>Access and Correction:</strong> You have the right to access
          and correct the personal information we hold about you.
        </p>
        <p>
          <strong>Deletion:</strong> You may request the deletion of your
          account and personal information at any time by contacting us. Please
          note that we may retain certain information as required by law or for
          legitimate business purposes.
        </p>
        <p>
          <strong>Opt-Out:</strong> You may opt out of receiving promotional
          communications from us by following the instructions in those
          communications or by updating your account settings.
        </p>

        <h3>7. Data Retention</h3>
        <p>
          We retain your personal information for as long as necessary to
          provide our services and for other legitimate business purposes. When
          your information is no longer needed, we will securely delete it.
        </p>

        <h3>8. International Users</h3>
        <p>
          If you are located outside the United States, please note that your
          information may be transferred to and processed in the United States
          and other countries where our service providers are located. By using
          Nexxy, you consent to the transfer and processing of your information
          in these countries.
        </p>

        <h3>9. California Residents</h3>
        <p>
          Under the California Consumer Privacy Act (CCPA), California residents
          have the right to:
        </p>
        <ul>
          <li>
            Know what personal information we collect, use, disclose, and sell.
          </li>
          <li>Request the deletion of personal information.</li>
          <li>Opt-out of the sale of personal information.</li>
        </ul>
        <p>
          To exercise these rights, California residents can contact us at
          "support@nexxy.app"
        </p>

        <h3>10. European Union (EU) Residents</h3>
        <p>
          If you are located in the EU, you have the following rights under the
          General Data Protection Regulation (GDPR):
        </p>
        <ul>
          <li>The right to access your personal data.</li>
          <li>The right to rectify inaccurate data.</li>
          <li>The right to erasure (right to be forgotten).</li>
          <li>The right to restrict processing.</li>
          <li>The right to data portability.</li>
          <li>The right to object to processing.</li>
        </ul>
        <p>
          To exercise these rights, EU residents can contact us at
          "support@nexxy.app".
        </p>

        <h3>11. Changes to the Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be effective when we post the updated policy. Your continued use of
          Nexxy after any changes indicates your acceptance of the new policy.
        </p>

        <h3>12. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at "support@nexxy.app"
        </p>
      </section>

      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <a href="/tos">Terms of Service</a>
          <a href="/CommunityGuideLines">Community GuideLines</a>
        </nav>
      </footer>
    </div>
  );
}

export default Privacy;
