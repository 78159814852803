import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";

function Home() {
  return (
    <div className="App">
      <Helmet>
        <title>Nexxy</title>
        <meta
          name="description"
          content="Join Nexxy, Connect, share, and explore like never before."
        />
        <meta
          name="keywords"
          content="Nexxy, social media, connect, share, explore, social networking"
        />
        <meta name="author" content="Nexxy LLC" />
        <link rel="canonical" href="https://www.nexxyapp.com" />

        <meta property="og:title" content="Nexxy" />
        <meta
          property="og:description"
          content="Join Nexxy for a new social media experience. Connect, share, and explore like never before."
        />
        <meta property="og:image" content="path-to-social-media-image.png" />
        <meta property="og:url" content="https://www.nexxyapp.com" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nexxy" />
        <meta name="twitter:description" content="Nexxy. Join today." />
        <meta name="twitter:image" content="path-to-social-media-image.png" />

        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "url": "https://www.nexxyapp.com",
              "name": "Nexxy",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.nexxyapp.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>

      <header className="App-header">
        <div className="logo">
          <img src="./Logo.png" alt="Nexxy Logo" className="App-logo" />
        </div>
        <nav className="nav-links">
          <a href="#download">Download</a>
          <a href="#waitlist">Wait List</a>
        </nav>
      </header>

      <section className="Hero">
        <div className="Hero-content">
          <div className="text-content">
            <h1>Nexxy</h1>
            <p>Join us, or not. cool sh*t is on here</p>
            <div className="cta-buttons">
              <button>App Store</button>
              <button>Play Store</button>
            </div>
          </div>
          <div className="screenshots">
            <img
              src="/AppDesign.png"
              alt="Nexxy App Screens"
              className="phone-image"
            />
          </div>
        </div>
      </section>

      <section className="Signup">
        <h2>Join Our Wait List</h2>
        <form>
          <input type="email" placeholder="Enter your email" required />
          <button type="submit">Join</button>
        </form>
      </section>

      <footer>
        <p>&copy; 2024 Nexxy LLC. All rights reserved.</p>
        <nav>
          <a href="/privacy">Privacy Policy</a>
          <a href="/tos">Terms of Service</a>
          <a href="/community-guidelines">Community GuideLines</a>
        </nav>
      </footer>
    </div>
  );
}

export default Home;
